/**
 * Here are all the Backend api calls for our modules
 */
import appApiClient from '../app-api-client'

const pageUriPrefix = "/sales";

export default {

    getIndexData() {
        return appApiClient().get(pageUriPrefix+'/index');
    },

    getClientInfoData(clientId) {
        return appApiClient().get(pageUriPrefix + '/clients/get-info/' + clientId);
    },

    getClientStatementData(clientId, data) {
        return appApiClient().post(pageUriPrefix + '/clients/get-statement/' + clientId, data);
    },

    getSalesOrderInfoData(id) {
        return appApiClient().get(pageUriPrefix + '/sales-orders/get-info/' + id);
    },
    getSalesOrderDocumentDownloadUrlData(id) {
        return appApiClient().get(pageUriPrefix + '/sales-orders/get-document-download-url/' + id);
    },
}