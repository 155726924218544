<template>
  <div>
    <div v-if="!salesOrderInfoApiDataIsLoading">
      <div class="row">
        <div class="col-12" v-if="salesOrderInfoApiDataErrorMessage != null">
          <div class="alert alert-danger">
            {{salesOrderInfoApiDataErrorMessage}}
          </div>
        </div>
        <div class="col-12" v-if="salesOrderDocumentDownloadUrlApiDataErrorMessage != null">
          <div class="alert alert-danger">
            {{salesOrderDocumentDownloadUrlApiDataErrorMessage}}
          </div>
        </div>
      </div>
      <div v-if="salesOrderInfoApiData != null">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="profile">
              <div class="profile-header">

                <div class="profile-header-cover"></div>

                <div class="profile-header-content">

                  <div class="profile-header-img">
                    <img src="/assets/img/document.png" alt="">
                  </div>

                  <div class="profile-header-info">
                    <h1 class="m-t-10 m-b-5">FACTURE DE VENTE</h1>
                    <h3 class="m-b-5">{{ salesOrderInfoApiData.server_side_code }} du
                      {{ salesOrderInfoApiData.formatted_creation_date }}</h3>
                    <h3 class="m-b-5">{{ salesOrderInfoApiData.customer_name }}</h3>
                  </div>

                </div>


                <ul class="profile-header-tab nav nav-tabs">
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('home')"
                                          v-bind:class="{ 'active': tab.home }" class="nav-link"
                                          data-toggle="tab">DETAILS FACTURE
                    ({{ salesOrderInfoApiData.items_count }})</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('payments')"
                                          v-bind:class="{ 'active': tab.payments }" class="nav-link"
                                          data-toggle="tab">DETAILS REGLEMENT
                    ({{ salesOrderInfoApiData.payments_count }})</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('stock')"
                                          v-bind:class="{ 'active': tab.stock }" class="nav-link"
                                          data-toggle="tab">MOUVEMENTS DE STOCK
                    ({{ salesOrderInfoApiData.related_stock_orders_count }})</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('notes')"
                                          v-bind:class="{ 'active': tab.notes }" class="nav-link"
                                          data-toggle="tab">OBSERVATIONS
                    ({{ salesOrderInfoApiData.notes_count }})</a></li>
                </ul>
              </div>
            </div>
            <div class="profile-content">
              <div class="tab-content p-0">
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.home }">
                  <div class="invoice">
                    <!-- begin invoice-company -->
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                                <a v-if="salesOrderDocumentDownloadUrlApiData == null"
                                                   class="btn btn-white m-b-10 mr-2" @click="onDocumentDownloadClick"><i
                                                    v-if="!salesOrderDocumentDownloadUrlApiDataIsLoading"
                                                    class="fa fa-download text-warning fa-fw fa-lg"></i><i
                                                    class="fa fa-spinner text-warning fa-fw fa-lg fa-spin"
                                                    v-if="salesOrderDocumentDownloadUrlApiDataIsLoading"></i> Télécharger</a>
                                                <a v-if="salesOrderDocumentDownloadUrlApiData != null &&  !salesOrderDocumentDownloadUrlApiDataIsLoading &&  salesOrderDocumentDownloadUrlApiData.pdf_url != null"
                                                   :href="salesOrderDocumentDownloadUrlApiData.pdf_url" target="_blank"
                                                   class="btn btn-white m-b-10 mr-2"><i
                                                    class="fa fa-file-pdf text-danger fa-fw fa-lg"></i> PDF</a>
                                                <a v-if="salesOrderDocumentDownloadUrlApiData != null &&  !salesOrderDocumentDownloadUrlApiDataIsLoading &&  salesOrderDocumentDownloadUrlApiData.excel_url != null"
                                                   :href="salesOrderDocumentDownloadUrlApiData.excel_url"
                                                   target="_blank"
                                                   class="btn btn-white m-b-10 mr-2"><i
                                                    class="fa fa-file-excel text-success fa-fw fa-lg"></i> Excel</a>
                                            </span>
                      <!--
                      <b-dropdown split text="Actions" class="btn-group pull-right hidden-print m-r-10" variant="default">
                          <b-dropdown-item href="#">Action 1</b-dropdown-item>
                          <b-dropdown-item href="#">Action 2</b-dropdown-item>
                          <b-dropdown-item href="#">Action 3</b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item href="#">Action 4</b-dropdown-item>
                      </b-dropdown>
                      -->
                      <h1>Facture de vente<br/>
                        <small>{{ salesOrderInfoApiData.server_side_code }} du
                          {{ salesOrderInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <!-- end invoice-company -->
                    <!-- begin invoice-header -->
                    <div class="invoice-header" style="background: red; padding: 20px;"
                         v-if="salesOrderInfoApiData.cancel_date">
                      <h1>
                        <small class="text-white f-fw-300">FACTURE ANNULEE</small>
                      </h1>
                    </div>
                    <div class="invoice-header">
                      <div class="invoice-from">
                        <small>De chez</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ salesOrderInfoApiData.company_name }}</strong><br/>
                          {{ salesOrderInfoApiData.company_address_line }}<br/>
                          {{ salesOrderInfoApiData.company_cluster_name }},
                          {{ salesOrderInfoApiData.company_location_name }}<br/>
                          Téléphone: {{ salesOrderInfoApiData.company_phone }}<br/>
                        </address>
                      </div>
                      <div class="invoice-to">
                        <small>Pour le client</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ salesOrderInfoApiData.customer_code }} |
                            {{ salesOrderInfoApiData.customer_name }}</strong><br/>
                          {{ salesOrderInfoApiData.customer_address_line }}<br/>
                          {{ salesOrderInfoApiData.customer_cluster_name }},
                          {{ salesOrderInfoApiData.customer_location_name }}<br/>
                          Téléphone: {{ salesOrderInfoApiData.customer_phone }}<br/>
                        </address>
                      </div>
                      <div class="invoice-date">
                        <small>Règlement</small>
                        <div class="date text-inverse m-t-5">
                          {{ salesOrderInfoApiData.order_amount_ttc|formatMoney }}
                        </div>
                        <div class="invoice-detail">
                          Payé <span
                            class="date text-inverse m-t-5">{{
                            salesOrderInfoApiData.order_total_payments|formatMoney
                          }}</span><br/>
                          Reste <span
                            class="date text-inverse m-t-5">{{
                            salesOrderInfoApiData.order_remainder_payments|formatMoney
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <!-- end invoice-header -->

                    <h3>{{ salesOrderInfoApiData.items_count }} Article(s)</h3>
                    <!-- begin invoice-content -->
                    <div class="invoice-content">
                      <!-- begin table-responsive -->
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th class="text-left">DESIGNATION</th>
                            <th class="text-center" width="15%">PRIX UNITAIRE</th>
                            <th class="text-center" width="15%">P.V RECOMMENDÉ</th>
                            <th class="text-center" width="15%">QTE</th>
                            <th class="text-right" width="15%">SOUS-TOTAL</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_item,index) in salesOrderInfoApiData.order_items"
                              :key="index">
                            <td><b>{{ index + 1 }}</b></td>
                            <td class="text-left">{{ order_item.product_name }}</td>
                            <td class="text-center">{{ order_item.unit_price|formatMoney }}</td>
                            <td class="text-center" v-if="order_item.recommended_unit_price && order_item.recommended_unit_price > order_item.unit_price">{{ order_item.recommended_unit_price|formatMoney }}</td>
                            <td class="text-center" v-else>-</td>
                            <td class="text-center">{{ order_item.quantity|formatDecimalNumber }}</td>
                            <td class="text-right"><b>{{
                                ((order_item.unit_price +
                                    order_item.unit_tax) * order_item.quantity)|formatMoney
                              }}</b>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                      <div class="invoice-price">
                        <div class="invoice-price-left">
                          <div class="invoice-price-row">
                            <div class="sub-price">
                              <small>TOTAL TTC</small>
                              <span class="text-inverse">{{ salesOrderInfoApiData.order_amount_ttc|formatMoney }}</span>
                            </div>
                            <div class="sub-price">
                              <i class="fa fa-minus text-muted"></i>
                            </div>
                            <div class="sub-price">
                              <small>REGLEMENTS</small>
                              <span
                                  class="text-inverse">{{
                                  salesOrderInfoApiData.order_total_payments|formatMoney
                                }}</span>
                            </div>
                            <div class="sub-price">
                              <i class="fa fa-equals text-muted"></i>
                            </div>
                            <div class="sub-price">
                              <small>RESTE A PAYER</small>
                              <span
                                  class="text-inverse">{{
                                  salesOrderInfoApiData.order_remainder_payments|formatMoney
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="invoice-price-right">
                          <small class="f-w-100"><b>MONTANT TTC</b></small>
                          <br/>
                          <span
                              class="f-w-600 text-yellow">{{
                              salesOrderInfoApiData.order_amount_ttc|formatMoney
                            }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-content -->
                    <!-- begin invoice-note --
                    <div class="invoice-note">
                        * Make all cheques payable to [Your Company Name]<br/>
                        * Payment is due within 30 days<br/>
                        * If you have any questions concerning this invoice, contact [Name, Phone
                        Number, Email]
                    </div>
                     end invoice-note -->
                    <!-- begin invoice-footer -->
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ salesOrderInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{
                            salesOrderInfoApiData.company_phone
                          }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ salesOrderInfoApiData.company_email }}</span>
                      </p>
                    </div>
                    <!-- end invoice-footer -->
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.payments }">
                  <div class="invoice">
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                            </span>
                      <h1>Règlement de facture<br/>
                        <small>{{ salesOrderInfoApiData.server_side_code }} du
                          {{ salesOrderInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <h3>{{ salesOrderInfoApiData.payments_count }} Règlement(s)</h3>
                    <div class="invoice-content">
                      <!-- begin table-responsive -->
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th class="text-left">DATE</th>
                            <th class="text-center">TYPE MOUVEMENT</th>
                            <th class="text-center">AGENT RECOUV.</th>
                            <th class="text-center">MODE DE PAIEMENT</th>
                            <th class="text-right">MONTANT</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_payment,index) in salesOrderInfoApiData.order_payments"
                              :key="index">
                            <td class="text-left">{{ order_payment.date }}</td>
                            <td class="text-center">{{ order_payment.type_name }}</td>
                            <td class="text-center">{{ order_payment.cashier_name }}</td>
                            <td class="text-center">{{ order_payment.payment_method_name }}
                            </td>
                            <td class="text-right">
                              <b>{{ order_payment.amount|formatMoney }}</b></td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                      <div class="invoice-price">
                        <div class="invoice-price-left">
                          <div class="invoice-price-row">
                            <div class="sub-price">
                              <small>TOTAL TTC</small>
                              <span class="text-inverse">{{ salesOrderInfoApiData.order_amount_ttc|formatMoney }}</span>
                            </div>
                            <div class="sub-price">
                              <i class="fa fa-minus text-muted"></i>
                            </div>
                            <div class="sub-price">
                              <small>REGLEMENTS</small>
                              <span
                                  class="text-inverse">{{
                                  salesOrderInfoApiData.order_total_payments|formatMoney
                                }}</span>
                            </div>
                            <div class="sub-price">
                              <i class="fa fa-equals text-muted"></i>
                            </div>
                            <div class="sub-price">
                              <small>RESTE A PAYER</small>
                              <span
                                  class="text-inverse">{{
                                  salesOrderInfoApiData.order_remainder_payments|formatMoney
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="invoice-price-right">
                          <small class="f-w-100"><b>TOTAL RÈGLEMENT</b></small>
                          <br/>
                          <span
                              class="f-w-600 text-yellow">{{
                              salesOrderInfoApiData.order_total_payments|formatMoney
                            }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ salesOrderInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{
                            salesOrderInfoApiData.company_phone
                          }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ salesOrderInfoApiData.company_email }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.stock }">
                  <div class="invoice">
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                            </span>
                      <h1>Mouvements de stock<br/>
                        <small>{{ salesOrderInfoApiData.server_side_code }} du
                          {{ salesOrderInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <h3>{{ salesOrderInfoApiData.related_stock_orders_count }} Mouvement(s)</h3>
                    <div class="invoice-content">
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th class="text-left">DATE</th>
                            <th class="text-center">REF. DOCUMENT</th>
                            <th class="text-center">TYPE</th>
                            <th class="text-right">ARTICLES</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(related_stock_order,index) in salesOrderInfoApiData.related_stock_orders"
                              :key="index">
                            <td class="text-left">{{ related_stock_order.date }}</td>
                            <td class="text-center"><b><a href="javascript:;"
                                                          @click="onStockOrderClick(related_stock_order)">{{
                                related_stock_order.server_side_code
                              }}</a></b>
                            </td>
                            <td class="text-center">{{ related_stock_order.type_name }}</td>
                            <td class="text-right"><b>{{ related_stock_order.items_count|formatMoney }}</b>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ salesOrderInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{
                            salesOrderInfoApiData.company_phone
                          }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ salesOrderInfoApiData.company_email }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.notes }">
                  <div class="invoice">
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                            </span>
                      <h1>Observations<br/>
                        <small>{{ salesOrderInfoApiData.server_side_code }} du
                          {{ salesOrderInfoApiData.formatted_creation_date }}
                        </small>
                      </h1>
                    </div>
                    <h3>{{ salesOrderInfoApiData.notes_count }} Observation(s)</h3>
                    <div class="invoice-content">
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th class="text-left">DATE</th>
                            <th class="text-center">AGENT</th>
                            <th class="text-right">OBSERVATION</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(order_note,index) in salesOrderInfoApiData.order_notes"
                              :key="index">
                            <td class="text-left">{{ order_note.date }}</td>
                            <td class="text-center">{{ order_note.writer }}</td>
                            <td class="text-right">{{ order_note.note }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ salesOrderInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{
                            salesOrderInfoApiData.company_phone
                          }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ salesOrderInfoApiData.company_email }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-loader" v-else><span class="spinner-small"></span></div>
  </div>
</template>


<script>
import pageApi from '../../store/api/sales/sales-dashboard-page-api'


export default {
  name: 'sales-order-component',
  components: {},
  props: ['itemId'],
  watch: {},
  computed: {},
  methods: {
    //Initialization Methods
    loadPageScripts() {
    },

    //
    show: function (x) {
      this.tab.home = false;
      this.tab.payments = false;
      this.tab.stock = false;
      this.tab.notes = false;

      switch (x) {
        case 'home':
          this.tab.home = true;
          break;
        case 'payments':
          this.tab.payments = true;
          break;
        case 'stock':
          this.tab.stock = true;
          break;
        case 'notes':
          this.tab.notes = true;
          break;
        default:
          this.tab.home = true;
          break;
      }
    },

    onStockOrderClick(stockOrder) {
      console.log(stockOrder.id);
    },

    onDocumentDownloadClick() {
      if (this.itemId) {
        this.getSalesOrderDocumentDownloadUrlData(this.itemId);
      }
    },

    //Other Methods
    async getSalesOrderInfoData(salesOrderId) {
      this.salesOrderInfoApiDataIsLoading = true;
      this.salesOrderInfoApiDataSuccessMessage = null;
      this.salesOrderInfoApiDataWarningMessage = null;
      this.salesOrderInfoApiDataErrorMessage = null;
      this.salesOrderInfoApiDataValidationErrors = [];
      try {
        const response = await pageApi.getSalesOrderInfoData(salesOrderId);
        //console.log(response);
        this.salesOrderInfoApiData = response.data;
        this.salesOrderInfoApiDataIsLoading = false;
        this.salesOrderInfoApiDataSuccessMessage = null;
        this.salesOrderInfoApiDataWarningMessage = null;
        this.salesOrderInfoApiDataErrorMessage = null;
        this.salesOrderInfoApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.salesOrderInfoApiDataIsLoading = false;
          this.salesOrderInfoApiDataSuccessMessage = null;
          this.salesOrderInfoApiDataWarningMessage = null;
          this.salesOrderInfoApiDataErrorMessage = error.response.data.message;
          this.salesOrderInfoApiDataValidationErrors = [];
        } else {
          this.salesOrderInfoApiDataIsLoading = false;
          this.salesOrderInfoApiDataSuccessMessage = null;
          this.salesOrderInfoApiDataWarningMessage = null;
          this.salesOrderInfoApiDataErrorMessage = error.message;
          this.salesOrderInfoApiDataValidationErrors = [];
        }
      }
    },

    async getSalesOrderDocumentDownloadUrlData(salesOrderId) {
      this.salesOrderDocumentDownloadUrlApiDataIsLoading = true;
      this.salesOrderDocumentDownloadUrlApiDataErrorMessage = null;
      try {
        const response = await pageApi.getSalesOrderDocumentDownloadUrlData(salesOrderId);
        //console.log(response);
        this.salesOrderDocumentDownloadUrlApiData = response.data;
        this.salesOrderDocumentDownloadUrlApiDataIsLoading = false;
        this.salesOrderDocumentDownloadUrlApiDataErrorMessage = null;
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.salesOrderDocumentDownloadUrlApiDataIsLoading = false;
          this.salesOrderDocumentDownloadUrlApiDataErrorMessage = error.response.data.message;
        } else {
          this.salesOrderDocumentDownloadUrlApiDataIsLoading = false;
          this.salesOrderDocumentDownloadUrlApiDataErrorMessage = error.message;
        }
      }
    },

  },
  data: function () {

    return {
      appName: window.appConfig.appName,
      appCurrency: window.appConfig.appCurrency,
      intervalId01: 0,

      //
      tab: {
        home: true,
        payments: false,
        stock: false,
        notes: false,
      },

      //API Data
      //

      salesOrderInfoApiData: null,
      salesOrderInfoApiDataIsLoading: false,
      salesOrderInfoApiDataSuccessMessage: null,
      salesOrderInfoApiDataWarningMessage: null,
      salesOrderInfoApiDataErrorMessage: null,
      salesOrderInfoApiDataValidationErrors: [],
      //
      salesOrderDocumentDownloadUrlApiData: null,
      salesOrderDocumentDownloadUrlApiDataIsLoading: false,
      salesOrderDocumentDownloadUrlApiDataErrorMessage: null,
    };
  },
  created: function () {
    if (this.itemId) {
      this.getSalesOrderInfoData(this.itemId);
    }
  },
  mounted: function () {
    this.loadPageScripts();
  },
  beforeDestroy: function () {
  },
}
</script>
